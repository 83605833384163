"use client";

import { useRouter } from 'next/navigation';
import React, { useState, useEffect } from 'react';

const SimpleCard: React.FC = () => {
    const text = `Knowledge is power.<br/><br/>
    
Every accomplished professional learnt to harness knowledge and connect dots to perform their best work and rise in their careers.<br/><br/>

You acquire new knowledge every day- a brilliant flash of inspiration, an experience you encounter or interesting media that you come across.<br/><br/>

But this knowledge is often lost, even if you capture it.<br/><br/>

It gets buried in scattered notes across stickies, whiteboards and countless apps that are either too simple to be effective or too complex to be practical. You end up having a graveyard of brilliant ideas that never see the light of day.<br/><br/>

This is why Quillow is building <u>an intelligent notepad for unstructured thought</u>.<br/><br/>

Let Artificial Intelligence transform your unstructured thoughts and bookmarks into a powerful, visual database of your knowledge.<br/><br/>

Think of it like a "second brain", but it doesn't just capture your knowledge- it is dynamic, grows with you and captures your intelligence too.<br/><br/>

It is your "<u>living</u> second brain".<br/><br/>`


   /* const text = `There is time and space between when you have a thought or an idea, and before you are ready to share and collaborate with others on it. This is where personal notes are handy.<br/><br/>
    
    Personal notes are letters to your future self.<br/><br/>
    
    You can write long letters of deliberate problem solving, or short letters of fleeting thoughts. You can write letters filled with just words or also write letters with links, pictures and diagrams. Your future self reads the letters as is, exactly as it was written. What this means is what your future self gets out of this whole process, is what your current self puts into it.<br/><br/>
    
    What you get out is what you put in.<br/><br/>
    
    In other words, fleeting thoughts remain fleeting thoughts. You may want powerful, organised personal knowledge but you need to have incredible discipline and put in the work to curate that.<br/><br/>
    
    This is why Quillow is building <u>an intelligent notepad for unstructured thought</u>.<br/><br/>
    
    It's simpler to use than Apple Notes and more powerful than Notion.<br/><br/>
    
    Let artificial intelligence (AI) transform your unstructured thoughts and bookmarks into an organised, powerful database of your knowledge. Visualise your knowledge in the way that suits you.<br/><br/>
    
    Think of it like a "second brain", if you've heard that concept, but much more than just a store of your knowledge. It's a store of your intelligence too. We call it creating your "<u>living</u> second brain".<br/><br/>`*/

    const sampleText = `Sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence <br/><br/>
    
    Sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence <br/><br/>

    Sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence <br/><br/>

    Sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence <br/><br/>

    Sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence <br/><br/>

    Sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence <br/><br/>

    Sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence <br/><br/>

    Sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence sentence <br/><br/>
    `

    const words = text.split(' ');
    const [visibleWords, setVisibleWords] = useState<string[]>([]);
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [isTextRevealed, setIsTextRevealed] = useState(false);
    const wordsPerSecond = 1; // Adjust this value to control the speed

    useEffect(() => {
        const intervalId = setInterval(() => {
            setVisibleWords((prev) => {
                const newWords = words.slice(currentWordIndex, currentWordIndex + wordsPerSecond);
                return [...prev, ...newWords];
            });
            setCurrentWordIndex((prev) => {
                const newIndex = prev + wordsPerSecond;
                if (newIndex >= words.length) {
                    clearInterval(intervalId);
                    setIsTextRevealed(true);
                }
                return newIndex;
            });
        }, 70);

        return () => clearInterval(intervalId);
    }, [words, currentWordIndex, wordsPerSecond]);

    const router = useRouter();

   /* useEffect(() => {
        console.log('useEffect');
        // Function to get a random class from the list
        const getRandomFontClass = () => {
            const fontClasses = [
                'font-playpen',
                'font-marck',
                'font-indie',
                'font-libre',
                'font-montserrat',
                'font-playfair'
            ];

            return 'font-playfair';
            //return fontClasses[Math.floor(Math.random() * fontClasses.length)];
        };

        // Apply the random font class to the element
        const randomFont = getRandomFontClass();
        console.log('randomFont = '+randomFont);
        const element = document.querySelector('.random-font');
        if (element) {
            element.classList.add(randomFont);
        }
    }, []);*/

    const handleButtonClick = () => {
        // Handle button click
        router.push('/early-access-form');
    }

    return (
        <div className="card font-playfair border border-gray-700 shadow-md p-4 rounded-lg max-w-screen-lg bg-[#222222] mx-4">
            
            <h1 className="text-4xl font-bold mb-8 text-[#FF5E20] text-center">Quillow</h1>
            <h2 className="text-2xl font-semibold mb-8 text-[#FF5E20] text-center">Your living second brain</h2>
            <p className="text-xl mb-8 text-[#FFFFFF]"
                dangerouslySetInnerHTML={{ __html: visibleWords.join(' ') }}
            />
            {isTextRevealed && (
                <div className="flex justify-center">
                <button className="bg-gradient-to-r from-orange-500 to-red-500 text-white font-bold py-4 px-8 rounded-lg hover:bg-gradient-to-l hover:from-red-700 hover:to-orange-700" onClick={handleButtonClick}>
                    Get Early Access
                </button>
                </div>
            )}
        </div>
    );
};

export default SimpleCard;